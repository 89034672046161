import React, { useState } from 'react';
import { GoogleButton } from '../../../../Layout/FramedLayout/SignUp/GoogleButton';
import { MicrosoftButton } from '../../../../features/authentication/components/MicrosoftButton';
import { coreService } from '../../../../services/core/core-service';
import { isValidEmail } from '../../../../utils/text';
import { useToast } from '../../../../hooks/useToast';
import { Button, Input, Spin } from 'antd';
import AuthenticationForm from '../../../../features/authentication/components/AuthenticationForm';
import Label from '../../../../features/shared/components/Label';
import Styles from './styles';

const {
  ActionsContainer,
  LoginLink,
  TermsContainer,
  FormContainer,
  SocContainer,
  SocLogo,
  TermsLabel,
  TermsLink,
  SecurityLink,
  ListContainer,
  SignInContainer,
  GoogleWorkspaceContainer,
  GoogleContainer,
  LoginOptions,
} = Styles;

const SignupForm: React.FC = () => {
  const [sso, setSso] = useState<Boolean | null>(null);
  const [email, setEmail] = useState('');

  const { error: errorToast } = useToast();

  const handleClick = async () => {
    const params = new URLSearchParams();
    const locationParams = new URLSearchParams(window.location.search);

    const utmSource = locationParams.get('utm_source') || '';
    const utmMedium = locationParams.get('utm_medium') || '';
    const utmCampaign = locationParams.get('utm_campaign') || '';
    const utmId = locationParams.get('utm_id') || '';
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    params.append('timezone', timezone);
    params.append('utm_source', utmSource);
    params.append('utm_medium', utmMedium);
    params.append('utm_campaign', utmCampaign);
    params.append('utm_id', utmId);

    const finalUrl = `${
      process.env.REACT_APP_API_CORE
    }/users/signup/microsoft?${params.toString()}`;

    window.location.replace(finalUrl);
  };

  const handleDomainCheck = async () => {
    if (!isValidEmail(email)) return;
    if (
      email.toLocaleLowerCase().includes('@disqo.com') ||
      email.toLocaleLowerCase().includes('@update.ai') ||
      email.toLocaleLowerCase().includes('@shiphero.com')
    ) {
      setSso(true);
      coreService
        .getSsoSignupUrl(email)
        .then(data => data.url)
        .then(data => {
          window.open(data, '_blank');
        })
        .catch(error => errorToast(`SSO is not enabled for your e-mail domain`))
        .finally(() => setSso(null));
    } else {
      setSso(false);
    }
  };

  if (sso === null) {
    return (
      <AuthenticationForm
        footer={
          <SocContainer>
            <SocLogo src="https://assets.update.ai/soc2.png" />
            <Label style={{ fontSize: '12px' }}>
              UpdateAI takes your data privacy and security seriously. Visit our
              <SecurityLink href="https://security.update.ai/" about="_blank">
                security portal
              </SecurityLink>{' '}
              to learn more.
            </Label>
          </SocContainer>
        }
        title="Create my account"
        description={`Let's get started with your 14-day free trial. No credit card required.`}
      >
        <Input placeholder="Enter your email" onChange={e => setEmail(e.target.value)} />
        <Button onClick={handleDomainCheck} disabled={!isValidEmail(email)}>
          Sign Up
        </Button>
      </AuthenticationForm>
    );
  }

  return (
    <>
      <AuthenticationForm
        footer={
          <SocContainer>
            <SocLogo src="https://assets.update.ai/soc2.png" />
            <Label style={{ fontSize: '12px' }}>
              UpdateAI takes your data privacy and security seriously. Visit our
              <SecurityLink href="https://security.update.ai/" about="_blank">
                security portal
              </SecurityLink>{' '}
              to learn more.
            </Label>
          </SocContainer>
        }
        title="Create my account"
        description={`Let's get started with your 14-day free trial. No credit card required.`}
      >
        <FormContainer>
          {sso ? (
            <Spin />
          ) : (
            <LoginOptions>
              <GoogleContainer>
                <GoogleWorkspaceContainer>
                  <Label style={{ color: '#a6a6a6' }}>
                    Use your Google Workspace or Microsoft business email.
                  </Label>
                </GoogleWorkspaceContainer>
                <GoogleButton />
              </GoogleContainer>

              <MicrosoftButton label="Sign up with Microsoft" handleClick={handleClick} />
            </LoginOptions>
          )}

          <ActionsContainer>
            <ListContainer>
              <SignInContainer>
                <Label>Already have an account?</Label>
                <LoginLink href="/login">Login here</LoginLink>
                <Label>.</Label>
              </SignInContainer>
            </ListContainer>
          </ActionsContainer>

          <TermsContainer>
            <TermsLabel>By signing up, you agree to our </TermsLabel>
            <TermsLink href="https://www.update.ai/legals/terms-of-use" target="_blank">
              Terms of Service
            </TermsLink>{' '}
            <TermsLabel>and</TermsLabel>{' '}
            <TermsLink href="https://www.update.ai/legals/privacy-policy" target="_blank">
              Privacy Policy
            </TermsLink>
            <TermsLabel>.</TermsLabel>
          </TermsContainer>
        </FormContainer>
      </AuthenticationForm>
    </>
  );
};

export default SignupForm;
