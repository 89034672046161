import { create } from 'zustand';
import { Invitation } from '../features/organizations/types';
import { produce } from 'immer';

interface OrganizationInvitesStore {
  invitations: Invitation[];
}

const organizationInvitesStore = create<OrganizationInvitesStore>(() => {
  return {
    invitations: [],
  };
});

const invitationsFetched = (invitations: Invitation[]) => {
  organizationInvitesStore.setState(() => ({ invitations }));
};

const invitationRevoked = (invitationId: string) => {
  organizationInvitesStore.setState(partial => {
    return produce(partial, draft => {
      draft.invitations = draft.invitations.filter(invitation => invitation.id !== invitationId);
    });
  });
};

const emailInvited = (invitation: Invitation) => {
  organizationInvitesStore.setState(partial => {
    return produce(partial, draft => {
      draft.invitations = draft.invitations.filter(
        invitation => invitation.email !== invitation.email,
      );
      draft.invitations.push(invitation);
    });
  });
};

export { emailInvited, invitationRevoked, invitationsFetched, organizationInvitesStore };
