import React, { useEffect } from 'react';
import AuthenticationLayout from '../../componentsV2/Layouts/AuthenticationLayout';
import SignupForm from './components/SignupForm';
import { useToast } from '../../hooks/useToast';
import { useSearchParams } from 'react-router-dom';

const SignupPage: React.FC = () => {
  const { neutral } = useToast();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('notification')) {
      neutral(searchParams.get('notification') as string);
      setSearchParams({});
    }
  });

  return <AuthenticationLayout leftSide={<SignupForm />} rightSide={<p></p>} />;
};

export default SignupPage;
