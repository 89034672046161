import React, { useEffect, useState } from 'react';
import AuthenticationLayout from '../../componentsV2/Layouts/AuthenticationLayout';
import SigninForm from './components/SigninForm';
import LoginForm from './components/LoginForm';
import { useToast } from '../../hooks/useToast';
import { useSearchParams } from 'react-router-dom';

const SigninPage: React.FC = () => {
  const [currentForm, setCurrentForm] = useState<'signin' | 'login'>('signin');
  const { neutral } = useToast();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('notification')) {
      neutral(searchParams.get('notification') as string);
      setSearchParams({});
    }
  });

  const formToDisplay =
    currentForm === 'signin' ? (
      <SigninForm onStandardLogin={() => setCurrentForm('login')} />
    ) : (
      <LoginForm onBack={() => setCurrentForm('signin')} />
    );

  return <AuthenticationLayout leftSide={formToDisplay} rightSide={<p></p>} />;
};

export default SigninPage;
